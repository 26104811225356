import React from 'react';
import './About.css';
import image1 from '../../../assets/student1.jpg';
import image2 from '../../../assets/student2.jpg';
import image3 from '../../../assets/student3.jpg';
import image4 from '../../../assets/student5.jpg';
import nike from '../../../assets/nike.png';
import tick from '../../../assets/whiteTick.png';

const About = () => {
    return (
        <div className='About' id='about'>
            <div className='left-r'>
                <img src={image1} alt='' />
                <img src={image2} alt='' />
                <img src={image3} alt='' />
                <img src={image4} alt='' />                
            </div>
            <div className='right-r'> 
                <span>Some reasons</span>
                <div>
                    <span className='stroke-text'>Why </span>
                    <span>choose us?</span>
                </div>

                <div className="details-r">
                <div>
                    <img src={tick} alt='' />
                    <span>Over 100 Opportunity and School</span>
                </div>
                <div>
                    <img src={tick} alt='' />
                    <span>Easy access to information about different fields</span>
                </div>
                <div>
                    <img src={tick} alt='' />
                    <span>Networking with different students and alumnies</span>
                </div>
                <div>
                    <img src={tick} alt='' />
                    <span>Reliable partners</span>
                </div>
                </div>
                <span style={{ 
                    color: 'var(--gray)', 
                    fontWeight: 'normal'
                    }}>
                    OUR PARTNERS
                </span>
                <div className='partners'>
                   <img src={nike} alt="" />
                   <img src={nike} alt="" />
                   <img src={nike} alt="" />
                </div>
            </div>
        </div>
    )
}

export default About