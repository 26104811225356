import React from 'react';
import Header from '../Header/Header';
import imagehero from '../../../assets/students.png';
import './Hero.css';
import NumberCounter from 'number-counter';

import { motion } from 'framer-motion';

const Hero = () => {
  const transition = {type: 'spring', duration : 3000}
  const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="hero" id='home'>
      <div className='blur hero-blur'></div>
      <div className="left-h">
        <Header/>
        {/*the best ad */}
        <div className='the-best-ad'>
            <motion.div 
                initial = {{left: mobile? "165px": '238px'}}
                whileInView = {{left: '8px'}}
                transition = {transition}>
                
            </motion.div>
            <span>the best orientation website for students</span>
        </div>

        {/*Hero heading */}
        <div className='hero-text'>
            <div>
                <span className='stroke-text'>Succeed </span>
                <span>Your</span>
            </div>
            <div><span>Ideal Career</span></div>
            <div>
                <span>
                  Explore the best schools for higher education in Morocco, with the best opportunities of scholarships, internships, exchange programs for youth, and more.
                </span> 
            </div>
        </div>

        {/* figures */}
        <div className='figures'>
           <div>
            <span>
              <NumberCounter end={50} start={10} delay='4' preFix="+" />
            </span>
            <span>Colleges & Schools</span>
           </div>
           <div>
            <span>
            <NumberCounter end={100} start={60} delay='4' preFix="+" />
            </span>
            <span>Student Programs</span>
           </div>
           <div>
            <span>
            <NumberCounter end={30} start={10} delay='4' preFix="+" />
            </span>
            <span>Expert coaches</span>
           </div>
        </div>

        {/* hero buttons */}
        <div className="hero-buttons">
            <buttons className="btn">Get Started</buttons>
            <buttons className="btn">Learn More</buttons>
        </div>


      </div>
      <div className="right-h">
         <div className="right-buttons">

          <button className="btn">Register</button>
          <button className="btn">Login</button>
         </div>

         {/* hero image */}
         <img src={imagehero} alt='' className='hero-image'/>

      </div>
    </div>
  );
}

export default Hero
