import './App.css';
import Hero from './components/FirstHome/Hero/Hero';
import Programs from './components/FirstHome/Programs/Programs';
import About from './components/FirstHome/About/About';
import Plans from './components/FirstHome/Plans/Plans';
import Testimonials from './components/FirstHome/Testimonials/Testimonials';
import Join from './components/FirstHome/Join/Join';
import Footer from './components/FirstHome/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Hero/>
      <Programs/>
      <About/>
      <Plans/>
      <Testimonials/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;
