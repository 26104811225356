import image1 from "../assets/nouha.jpeg";
import image2 from "../assets/abdellahBen.jpeg";
import image3 from "../assets/abdellahEl.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Tri9i program and by choosing the right plan and program I already achieved my ideal profession",
    name: 'Nouhaila Chab',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'Abdellah Benmessaoud',
    status: 'COACH'
  },
  {
    image : image3,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'Abdellah ElAaroub',
    status: "SCHOOL DIRECTOR"
  }
];
